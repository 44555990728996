import { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { requestUpdateOneTimeUseTag, requestCreateOneTimeUseTag, requestGetTagById } from 'api/RepairProcedureApi';
import { colors, validatePath, getValidIds, getCssPath, clearHighlight } from 'helpers/TaggerHelper';
import { LoadingContext } from 'components/Layout';
import { FormatCurrency } from 'helpers/CurrencyHelper';
import { ToastContext } from 'components/ToastProvider';
import { TaggingWorkFlowStatusEnum } from 'helpers/FlagTagHelper';
import { isEqual } from 'lodash';

const useOneTimeUseTagUpdate = (tag, setIsUpdatingTag, setTags, quantityConditions) => {
    const init = {
        title: tag.title ?? '',
        quantity: tag.quantity?.toString() ?? '',
        quantityCondition: tag.quantityCondition ?? 'none',
        partNumber: tag.partNumber ?? '',
        price: FormatCurrency(tag.price ? tag.price : 0, 2),
        oneTimeUsePartTypeId: tag.oneTimeUsePartType ? tag.oneTimeUsePartType.oneTimeUsePartTypeId : '',
        text: tag.text ?? '',
        note: tag.note ?? '',
        oneTimeUseTagImages: tag.oneTimeUseTagImages ? tag.oneTimeUseTagImages.map(t => t.imageUrl) : [],
        highlightStartPath: '',
        highlightEndPath: '',
    };

    const [tagName, setTagName] = useState(init.title);
    const [quantity, setQuantity] = useState(init.quantity);
    const [quantityCondition, setQuantityCondition] = useState(init.quantityCondition);
    const [partNumber, setPartNumber] = useState(init.partNumber);
    const [price, setPrice] = useState(init.price);
    const [partType, setPartType] = useState(init.oneTimeUsePartTypeId);
    const [tagText, setTagText] = useState(init.text);
    const [note, setNote] = useState(init.note);
    const [selectedImages, setSelectedImages] = useState(init.oneTimeUseTagImages);
    const [highlightStartPath, setHighlightStartPath] = useState('');
    const [highlightEndPath, setHighlightEndPath] = useState('');
    const [isSelectingImg, setIsSelectingImage] = useState(false);
    const { procedureId } = useParams();
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext(ToastContext);
    const calcConditions = useMemo(
        () =>
            quantityConditions.some(c => c.value === tag.quantityCondition)
                ? quantityConditions
                : [...quantityConditions, { value: tag.quantityCondition, label: tag.quantityCondition }],
        [tag, quantityConditions]
    );

    const isChanged =
        init.title !== tagName ||
        init.quantity !== quantity ||
        init.quantityCondition !== quantityCondition ||
        init.partNumber !== partNumber ||
        FormatCurrency(init.price).value !== FormatCurrency(price).value ||
        init.oneTimeUsePartTypeId !== partType ||
        init.text !== tagText ||
        init.note !== note ||
        !isEqual(init.oneTimeUseTagImages, selectedImages) ||
        init.highlightStartPath !== highlightStartPath ||
        init.highlightEndPath !== highlightEndPath;
    const isTitleBlank = !tagName;

    const handleDocumentClick = useCallback(
        event => {
            if (isSelectingImg) {
                const currentTarget = event.target;
                if (currentTarget.tagName === 'IMG') {
                    let currentImages = [...selectedImages];
                    currentImages.push(currentTarget.attributes.src.value);
                    setSelectedImages(currentImages);

                    setIsSelectingImage(false);
                }
                if (currentTarget.tagName === 'DIV' && currentTarget.classList.contains('object-wrapper')) {
                    let currentImages = [...selectedImages];
                    let imageObjects = currentTarget.querySelectorAll('object');
                    let src = imageObjects[0].data;
                    currentImages.push(src);
                    setSelectedImages(currentImages);

                    setIsSelectingImage(false);
                }
            }
        },
        [isSelectingImg, selectedImages]
    );

    useEffect(() => {
        let objects = document.querySelectorAll('object.svg-image');
        for (let i = 0; i < objects.length; i++) {
            if (objects[i].parentNode.classList.contains('object-wrapper')) continue;
            const wrapper = document.createElement('div');
            objects[i].parentNode.insertBefore(wrapper, objects[i]);
            objects[i].classList.add('pe-none');
            wrapper.appendChild(objects[i]);
            wrapper.classList.add('object-wrapper');
        }
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [isSelectingImg, handleDocumentClick]);

    const handleRemoveImageClick = img => {
        let newSelectedImages = selectedImages.filter(i => i !== img);
        setSelectedImages(newSelectedImages);
    };

    const handleSelectTagRegion = () => {
        let selection = document.getSelection();
        if (!selection) return;

        const range = selection.getRangeAt(0);

        let start = getCssPath(range.startContainer.parentNode);
        let end = start.includes('li') || start.includes('td') ? start : getCssPath(range.endContainer.parentNode);

        const actualRange = document.createRange();
        actualRange.setStartBefore(document.querySelector(start), 0);
        actualRange.setEndAfter(document.querySelector(end), 0);

        let validIds = getValidIds(parseInt(procedureId));
        let validPath = validatePath(start, validIds) && validatePath(end, validIds);
        const clientRect = actualRange.getBoundingClientRect();
        const highlight = document.getElementById('highlight');
        const spacing = 3;
        highlight.style.pointerEvents = 'none';
        highlight.style.left = `${clientRect.x + window.scrollX - spacing}px`;
        highlight.style.top = `${clientRect.y + window.scrollY - spacing}px`;
        // prettier-ignore
        highlight.style.width = `${clientRect.width + (2 * spacing)}px`;
        // prettier-ignore
        highlight.style.height = `${clientRect.height + (2 * spacing)}px`;

        if (!validPath) highlight.style.background = '#F44336';
        else highlight.style.background = '#4CAF50';

        setHighlightStartPath(start);
        setHighlightEndPath(end);

        // Update TagText
        setTagText(selection.toString());
    };

    const handleRevertRegion = () => {
        clearHighlight();
        setHighlightStartPath('');
        setHighlightEndPath('');
        setTagText(tag.text);
    };

    const handleSplitTagClick = async e => {
        try {
            incrementLoading();
            e.stopPropagation();
            let tagColor = colors[Math.floor(Math.random() * colors.length)];
            let createOneTimeUseTagCommand = [
                {
                    oneTimeUseFlagId: tag.oneTimeUseFlagId,
                    procedureId: parseInt(procedureId),
                    title: tag.title,
                    quantity: parseFloat(tag.quantity),
                    quantityCondition: tag.quantityCondition,
                    partNumber: tag.partNumber,
                    price: parseFloat(tag.price),
                    text: tag.text,
                    colorHex: tagColor,
                    note: tag.note,
                    workFlowStatusid: TaggingWorkFlowStatusEnum.IN_REVIEW.Id,
                    oneTimeUsePartTypeid: tag.oneTimeUsePartType ? tag.oneTimeUsePartType.oneTimeUsePartTypeId : null,
                    startPath:
                        highlightStartPath === '' && tag.oneTimeUseTagElements.length > 0
                            ? tag.oneTimeUseTagElements[0].contentStart
                            : highlightStartPath,
                    endPath:
                        highlightEndPath === '' && tag.oneTimeUseTagElements.length > 0
                            ? tag.oneTimeUseTagElements[0].contentEnd
                            : highlightEndPath,
                    tagImages: tag.oneTimeUseTagImages && tag.oneTimeUseTagImages.map(i => i.imageUrl),
                },
            ];

            let newTagIds = await requestCreateOneTimeUseTag(createOneTimeUseTagCommand);
            if (newTagIds.length) {
                const id = newTagIds[0];
                const newTag = await requestGetTagById(id);
                setTags(prev => [...prev, newTag]);
            }

            setIsUpdatingTag(false);
        } catch (error) {
            showToast(error);
        } finally {
            decrementLoading();
        }
    };

    const handleUpdateTag = async () => {
        try {
            incrementLoading();
            const updateCommand = {
                stagedOneTimeUseTagId: tag.stagedOneTimeUseTagId,
                title: tagName,
                quantity: quantity !== '' ? parseFloat(quantity) : null,
                quantityCondition: quantityCondition,
                partNumber: partNumber,
                price: price !== '' ? parseFloat(price) : null,
                text: tagText,
                colorHex: tag.colorHex,
                note: note,
                linksChecked: tag.linksChecked,
                workFlowStatusId: 1, // change tag status to In Review after updating for reviewing
                oneTimeUsePartTypeId: partType !== '' ? parseInt(partType) : null,
                startPath: highlightStartPath === '' ? null : highlightStartPath,
                endPath: highlightEndPath === '' ? null : highlightEndPath,
                isChangingImages: true,
                tagImages: selectedImages.length > 0 ? selectedImages : null,
                isActive: true, // change tag status to active after updating for reviewing
            };

            const { updatedStagedTagIds, orphanedStagedTagIds } = await requestUpdateOneTimeUseTag([updateCommand]);
            if (updatedStagedTagIds.length) {
                const id = updatedStagedTagIds[0];
                // updated this tag
                const newTagDetail = await requestGetTagById(id);
                setTags(prev =>
                    prev.map(t => {
                        const tId = t.stagedOneTimeUseTagId;
                        if (tId === id) return newTagDetail;
                        if (orphanedStagedTagIds.includes(tId)) {
                            // we reset tag link of orphaned tag
                            const copy = { ...t };
                            copy.oneTimeUseTagLink = null;
                            return copy;
                        }
                        return t;
                    })
                );
            }

            clearHighlight();
            setIsUpdatingTag(false);
        } catch (error) {
            showToast(error);
        } finally {
            decrementLoading();
        }
    };

    return {
        tagName,
        setTagName,
        quantity,
        setQuantity,
        quantityCondition,
        setQuantityCondition,
        partNumber,
        setPartNumber,
        price,
        setPrice,
        partType,
        setPartType,
        isSelectingImg,
        setIsSelectingImage,
        selectedImages,
        tagText,
        setTagText,
        note,
        setNote,
        handleRemoveImageClick,
        handleSelectTagRegion,
        handleRevertRegion,
        handleSplitTagClick,
        handleUpdateTag,
        isChanged,
        isTitleBlank,
        calcConditions,
        isActive: tag.isActive,
    };
};

export default useOneTimeUseTagUpdate;
