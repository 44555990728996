import { useCallback, useContext } from 'react';
import { LargeCheckbox } from 'oemiq-common';
import OEMModelSearch from '../OEMModelSearch';
import ThreeDModelSearch from '../ThreeDModelSearch';
import { BULK_ASSIGN_VEHICLE_ACTION_TYPE } from './BulkAssignVehicleModal';
import { requestFindVehicleVisualModel } from 'api/vehicleInfo';
import { ToastContext } from 'components/ToastProvider';

const SectionHeader = ({ id, text, checked, onCheckboxChange }) => {
    return (
        <>
            <div id={id} className="d-flex flex-row justify-content-flex-start align-items-center">
                <LargeCheckbox
                    id={`${id}-checkbox`}
                    checked={checked}
                    onChange={onCheckboxChange}
                    title="Click to enable this section"
                />
                <div className={`${checked ? 'text-success' : 'text-secondary'} mx-3 h4 m-0`}>{text}</div>
            </div>
        </>
    );
};

const TrimLevelFilteringSection = ({
    disabled,
    isTrimLevelFilteringOn,
    onSectionToggle,
    onTrimLevelFilteringToggle,
}) => {
    return (
        <>
            <div className="d-flex flex-column justify-content-center">
                <SectionHeader
                    id="bulk-assign-vehicle-modal-trim-level-filtering-section"
                    text="Trim Level Filtering"
                    checked={!disabled}
                    onCheckboxChange={onSectionToggle}
                />
                <div className="switch switch-sm my-3 align-self-center">
                    <input
                        disabled={disabled}
                        type="checkbox"
                        className="switch"
                        id="bulk-assign-vehicle-modal-trim-level-filtering-switch"
                        checked={isTrimLevelFilteringOn}
                        onChange={onTrimLevelFilteringToggle}
                    />
                    <label
                        style={{ transform: 'scale(1.5)' }}
                        htmlFor="bulk-assign-vehicle-modal-trim-level-filtering-switch"></label>
                </div>
            </div>
            <hr />
        </>
    );
};

const IsReviewedSection = ({ disabled, isReviewedOn, onSectionToggle, onIsReviewedToggle }) => {
    return (
        <>
            <div className="d-flex flex-column justify-content-center">
                <SectionHeader
                    id="bulk-assign-vehicle-modal-is-reviewed-section"
                    text="Is Reviewed"
                    checked={!disabled}
                    onCheckboxChange={onSectionToggle}
                />
                <div className="switch switch-sm my-3 align-self-center">
                    <input
                        disabled={disabled}
                        type="checkbox"
                        className="switch"
                        id="bulk-assign-vehicle-modal-is-reviewed-switch"
                        checked={isReviewedOn}
                        onChange={onIsReviewedToggle}
                    />
                    <label
                        style={{ transform: 'scale(1.5)' }}
                        htmlFor="bulk-assign-vehicle-modal-is-reviewed-switch"></label>
                </div>
            </div>
            <hr />
        </>
    );
};

const ThreeDModelSection = ({
    disabled,
    loading,
    onSectionToggle,
    modelNameSearchValue,
    onModelNameChange,
    modelYearSearchValue,
    onModelYearChange,
    visualModelOptions,
    visualModelValue,
    onVisualModelChange,
    onFindButtonClick,
    onUnassignButtonClick,
}) => {
    return (
        <>
            <div className="d-flex flex-column justify-content-center">
                <SectionHeader
                    id="bulk-assign-vehicle-modal-3d-model-section"
                    text="3D Model"
                    checked={!disabled}
                    onCheckboxChange={onSectionToggle}
                />
                <ThreeDModelSearch
                    id="bulk-assign-vehicle-modal-3d-model-search"
                    loading={loading}
                    className="mb-3"
                    disabled={disabled}
                    modelNameSearchValue={modelNameSearchValue}
                    modelYearSearchValue={modelYearSearchValue}
                    onModelNameChange={onModelNameChange}
                    onModelYearChange={onModelYearChange}
                    visualModelOptions={visualModelOptions}
                    visualModelValue={visualModelValue}
                    onVisualModelChange={onVisualModelChange}
                    onFindButtonClick={onFindButtonClick}
                    onUnassignButtonClick={onUnassignButtonClick}
                />
            </div>
            <hr />
        </>
    );
};

const OEMModelSection = ({
    disabled,
    onSectionToggle,
    loading,
    oemModelOptions,
    oemModelValue,
    onOemModelChange,
    onUnassignButtonClick,
}) => {
    return (
        <>
            <div className="d-flex flex-column justify-content-center">
                <SectionHeader
                    id="bulk-assign-vehicle-modal-oem-model-name-section"
                    text="OEM Models"
                    checked={!disabled}
                    onCheckboxChange={onSectionToggle}
                />
                <OEMModelSearch
                    id="bulk-assign-vehicle-modal-oem-model-search"
                    className="mb-3"
                    disabled={disabled}
                    loading={loading}
                    oemModelOptions={oemModelOptions}
                    oemModelValue={oemModelValue}
                    onOemModelChange={onOemModelChange}
                    onUnassignButtonClick={onUnassignButtonClick}
                />
            </div>
            <hr />
        </>
    );
};

const DisplayTagsSection = ({
    disabled,
    isDisplayingTagsOn,
    onSectionToggle,
    onDisplayingTagsToggle,
    incompletedTaggingVehicles,
}) => {
    return (
        <>
            <div className="d-flex flex-column justify-content-center">
                <SectionHeader
                    id="bulk-assign-vehicle-modal-display-tag-section"
                    text="Display Tags"
                    checked={!disabled}
                    onCheckboxChange={onSectionToggle}
                />
                {incompletedTaggingVehicles.length === 0 ? (
                    <div className="switch switch-sm my-3 align-self-center">
                        <input
                            disabled={disabled}
                            type="checkbox"
                            className="switch"
                            id="bulk-assign-vehicle-modal-display-tags-switch"
                            data-testid="bulk-assign-vehicle-modal-display-tags-switch"
                            checked={isDisplayingTagsOn}
                            onChange={onDisplayingTagsToggle}
                        />
                        <label
                            style={{ transform: 'scale(1.5)' }}
                            htmlFor="bulk-assign-vehicle-modal-display-tags-switch"></label>
                    </div>
                ) : (
                    <div className="m-3">
                        <div>Found vehicles with incompleted tagging status</div>
                        <div className="mt-2" style={{ maxHeight: '350px', overflowY: 'auto' }}>
                            <table className="table table-striped table-sm">
                                <thead style={{ position: 'sticky', top: '0', backgroundColor: 'white' }}>
                                    <tr>
                                        <th scope="col">VehicleID</th>
                                        <th scope="col"># of unresolved flags</th>
                                        <th scope="col"># of incompleted tags</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {incompletedTaggingVehicles.map(v => (
                                        <tr key={v.vehicleId}>
                                            <td scope="row">{v.vehicleId}</td>
                                            <td>{v.taggerStatistics.unresolvedFlagCount}</td>
                                            <td>{v.taggerStatistics.activeIncompletedTagCount}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
            <hr />
        </>
    );
};

const InformationSection = ({
    trimLevelFilteringSectionDisabled,
    isTrimLevelFilteringOn,
    threeDModelSectionDisabled,
    visualModelValue,
    oemModelSectionDisabled,
    oemModelValue,
    displayTagsSectionDisabled,
    isDisplayingTagsOn,
    isReviewedSectionDisabled,
    isReviewedOn,
}) => {
    let lis = [];
    if (!trimLevelFilteringSectionDisabled) {
        lis = [...lis, `Trim level filtering bit set to: ${isTrimLevelFilteringOn ? 'True (yes)' : 'False (no)'}`];
    }
    if (!threeDModelSectionDisabled) {
        lis = [...lis, `3D models ${visualModelValue ? `set to: ${visualModelValue.label}` : 'unassigned'}`];
    }
    if (!oemModelSectionDisabled) {
        lis = [...lis, `OEM models ${oemModelValue ? `set to: ${oemModelValue.label}` : 'unassigned'}`];
    }
    if (!displayTagsSectionDisabled) {
        lis = [
            ...lis,
            `Displaying tags bit set to: ${isDisplayingTagsOn ? 'True (yes)' : 'False (no)'}${
                isDisplayingTagsOn ? '' : '. Need to turn on!'
            }`,
        ];
    }
    if (!isReviewedSectionDisabled) {
        lis = [...lis, `Vehicle Is Reviewed bit set to ${isReviewedOn ? 'True (yes)' : 'False (no)'}`];
    }
    return (
        <div style={{ minHeight: '80px' }}>
            {!trimLevelFilteringSectionDisabled ||
            !threeDModelSectionDisabled ||
            !oemModelSectionDisabled ||
            !displayTagsSectionDisabled ||
            !isReviewedSectionDisabled ? (
                <>
                    <div>
                        After clicking <strong>Apply</strong> button, all selected vehicles will have:
                    </div>
                    {lis.map(li => (
                        <li key={li}>{li}</li>
                    ))}
                </>
            ) : null}
        </div>
    );
};

const BulkAssignVehicleModalBody = ({
    isReviewedSectionDisabled,
    isReviewedOn,
    trimLevelFilteringSectionDisabled,
    isTrimLevelFilteringOn,
    threeDModelSectionDisabled,
    threeDModelSectionLoading,
    threeDModelNameSearchValue,
    threeDModelYearSearchValue,
    visualModelOptions,
    visualModelValue,
    oemModelSectionDisabled,
    oemModelSectionLoading,
    oemModelOptions,
    oemModelValue,
    dispatch,
    displayTagsSectionDisabled,
    isDisplayingTagsOn,
    selectedVehicles,
}) => {
    const { showToast } = useContext(ToastContext);

    const handleTrimLevelFilteringSectionToggle = useCallback(
        () => dispatch({ type: BULK_ASSIGN_VEHICLE_ACTION_TYPE.TOGGLE_TRIM_LEVEL_FILTERING_SECTION }),
        [dispatch]
    );

    const handleTrimLevelFilteringToggle = useCallback(
        () => dispatch({ type: BULK_ASSIGN_VEHICLE_ACTION_TYPE.TOGGLE_TRIM_LEVEL_FILTERING }),
        [dispatch]
    );

    const handle3dModelSectionToggle = useCallback(
        () => dispatch({ type: BULK_ASSIGN_VEHICLE_ACTION_TYPE.TOGGLE_3D_MODEL_SECTION }),
        [dispatch]
    );

    const handle3dModelNameChange = useCallback(
        e =>
            dispatch({
                type: BULK_ASSIGN_VEHICLE_ACTION_TYPE.SET_3D_MODEL_NAME_SEARCH,
                payload: e.currentTarget.value,
            }),
        [dispatch]
    );

    const handle3dModelYearChange = useCallback(
        e =>
            dispatch({
                type: BULK_ASSIGN_VEHICLE_ACTION_TYPE.SET_3D_MODEL_YEAR_SEARCH,
                payload: e.currentTarget.value,
            }),
        [dispatch]
    );

    const handleVisualModelChange = useCallback(
        e => dispatch({ type: BULK_ASSIGN_VEHICLE_ACTION_TYPE.SET_VISUAL_MODEL, payload: e }),
        [dispatch]
    );

    const handle3dModelFindButtonClick = useCallback(async () => {
        try {
            dispatch({ type: BULK_ASSIGN_VEHICLE_ACTION_TYPE.SET_3D_MODEL_SECTION_LOADING, payload: true });
            let years = [];
            if (threeDModelYearSearchValue) {
                years = [parseInt(threeDModelYearSearchValue)];
            }
            const response = await requestFindVehicleVisualModel(threeDModelNameSearchValue, years);

            const options = response.results.map(vm => ({
                label: `${vm.visualModelName}`,
                value: vm.visualModelId,
            }));
            dispatch({ type: BULK_ASSIGN_VEHICLE_ACTION_TYPE.SET_VISUAL_MODEL_OPTIONS, payload: options });
        } catch (error) {
            showToast();
        } finally {
            dispatch({ type: BULK_ASSIGN_VEHICLE_ACTION_TYPE.SET_3D_MODEL_SECTION_LOADING, payload: false });
        }
    }, [threeDModelNameSearchValue, threeDModelYearSearchValue, dispatch, showToast]);

    const handle3dModelUnassignButtonClick = useCallback(
        () => dispatch({ type: BULK_ASSIGN_VEHICLE_ACTION_TYPE.SET_VISUAL_MODEL, payload: null }),
        [dispatch]
    );

    const handleOEMModelSectionToggle = useCallback(
        () => dispatch({ type: BULK_ASSIGN_VEHICLE_ACTION_TYPE.TOGGLE_OEM_MODEL_SECTION }),
        [dispatch]
    );

    const handleOEMModelChange = useCallback(
        e => dispatch({ type: BULK_ASSIGN_VEHICLE_ACTION_TYPE.SET_OEM_MODELS, payload: e }),
        [dispatch]
    );

    const handleOEMModelUnassignButtonClick = useCallback(
        () => dispatch({ type: BULK_ASSIGN_VEHICLE_ACTION_TYPE.SET_OEM_MODELS, payload: null }),
        [dispatch]
    );

    const incompletedTaggingVehicles = selectedVehicles.filter(
        v =>
            !v.isDisplayingTagsEnabled &&
            v.taggerStatistics.unresolvedFlagCount + v.taggerStatistics.activeIncompletedTagCount > 0
    );

    const handleDisplayTagsSectionToggle = useCallback(() => {
        if (incompletedTaggingVehicles.length === 0) {
            // enable toggling this section only if tagging statuses of selected vehicles are completed
            dispatch({ type: BULK_ASSIGN_VEHICLE_ACTION_TYPE.TOGGLE_DISPLAY_TAGS_SECTION });
        }
    }, [dispatch, incompletedTaggingVehicles]);

    const handleDisplayTagsToggle = useCallback(() => {
        dispatch({ type: BULK_ASSIGN_VEHICLE_ACTION_TYPE.TOGGLE_DISPLAY_TAGS });
    }, [dispatch]);

    const handleIsReviewedToggle = useCallback(
        () => dispatch({ type: BULK_ASSIGN_VEHICLE_ACTION_TYPE.TOGGLE_IS_REVIEWED }),
        [dispatch]
    );

    const handleIsReviewedSectionToggle = useCallback(
        () => dispatch({ type: BULK_ASSIGN_VEHICLE_ACTION_TYPE.TOGGLE_IS_REVIEWED_SECTION }),
        [dispatch]
    );

    return (
        <div>
            <TrimLevelFilteringSection
                disabled={trimLevelFilteringSectionDisabled}
                onSectionToggle={handleTrimLevelFilteringSectionToggle}
                isTrimLevelFilteringOn={isTrimLevelFilteringOn}
                onTrimLevelFilteringToggle={handleTrimLevelFilteringToggle}
            />
            <ThreeDModelSection
                disabled={threeDModelSectionDisabled}
                onSectionToggle={handle3dModelSectionToggle}
                loading={threeDModelSectionLoading}
                modelNameSearchValue={threeDModelNameSearchValue}
                modelYearSearchValue={threeDModelYearSearchValue}
                onModelNameChange={handle3dModelNameChange}
                onModelYearChange={handle3dModelYearChange}
                visualModelOptions={visualModelOptions}
                visualModelValue={visualModelValue}
                onVisualModelChange={handleVisualModelChange}
                onFindButtonClick={handle3dModelFindButtonClick}
                onUnassignButtonClick={handle3dModelUnassignButtonClick}
            />
            <OEMModelSection
                disabled={oemModelSectionDisabled}
                onSectionToggle={handleOEMModelSectionToggle}
                loading={oemModelSectionLoading}
                oemModelOptions={oemModelOptions}
                oemModelValue={oemModelValue}
                onOemModelChange={handleOEMModelChange}
                onUnassignButtonClick={handleOEMModelUnassignButtonClick}
            />
            <DisplayTagsSection
                disabled={displayTagsSectionDisabled}
                isDisplayingTagsOn={isDisplayingTagsOn}
                onSectionToggle={handleDisplayTagsSectionToggle}
                onDisplayingTagsToggle={handleDisplayTagsToggle}
                incompletedTaggingVehicles={incompletedTaggingVehicles}
            />
            <IsReviewedSection
                disabled={isReviewedSectionDisabled}
                onSectionToggle={handleIsReviewedSectionToggle}
                isReviewedOn={isReviewedOn}
                onIsReviewedToggle={handleIsReviewedToggle}
            />
            <InformationSection
                trimLevelFilteringSectionDisabled={trimLevelFilteringSectionDisabled}
                isTrimLevelFilteringOn={isTrimLevelFilteringOn}
                threeDModelSectionDisabled={threeDModelSectionDisabled}
                visualModelValue={visualModelValue}
                oemModelSectionDisabled={oemModelSectionDisabled}
                oemModelValue={oemModelValue}
                displayTagsSectionDisabled={displayTagsSectionDisabled}
                isDisplayingTagsOn={isDisplayingTagsOn}
                isReviewedSectionDisabled={isReviewedSectionDisabled}
                isReviewedOn={isReviewedOn}
            />
        </div>
    );
};

export default BulkAssignVehicleModalBody;
