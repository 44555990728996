import type { DataItem } from 'components/Shared/Table/types';

export interface ImportJobBook {
    importJobId: number;
    importJobBookId: string;
    rpBookId: number;
    success: boolean | null;
    bookFriendlyName: string | null;
    ImportJobBookCommand: string | null;
}

export interface ImportJobBookStats {
    mappingStatus: string;
}

export interface ImportJobDetailsRowDataType extends ImportJobBook, DataItem {
    stats: ImportJobBookStats | null;
}

export enum ImportJobDetailsActionsTypes {
    RunBook = '1',
    ViewImportJobBookCommand = '2',
    ViewProcedure = '3',
    GoToTags = '4',
    RequeueFlagging = '5',
    RetryFailedFlagging = '6',
}
