import { ToastContext } from 'components/ToastProvider';
import { ReactModal } from 'oemiq-common';
import { useCallback, useContext, useReducer } from 'react';
import { requestFindVehicleOemModel } from 'api/vehicleInfo';
import { useEffect } from 'react';

import BulkAssignVehicleModalHeader from './BulkAssignVehicleModalHeader';
import BulkAssignVehicleModalFooter from './BulkAssignVehicleModalFooter';
import BulkAssignVehicleModalBody from './BulkAssignVehicleModalBody';

const BULK_ASSIGN_VEHICLE_INIT_STATE = {
    isReviewed: {
        sectionDisabled: true,
        isChecked: false,
    },
    trimLevelFiltering: {
        sectionDisabled: true,
        isChecked: false,
    },
    threeDModel: {
        sectionDisabled: true,
        loading: false,
        modelNameSearchValue: '',
        modelYearSearchValue: '', // model year id (not value)
        visualModelOptions: [],
        visualModelValue: null,
    },
    oemModels: {
        sectionDisabled: true,
        loading: false,
        oemModelOptions: [],
        oemModelValue: null,
    },
    displayTags: {
        sectionDisabled: true,
        isChecked: false,
    },
};

export const BULK_ASSIGN_VEHICLE_ACTION_TYPE = {
    RESET: 1,
    TOGGLE_TRIM_LEVEL_FILTERING_SECTION: 2,
    TOGGLE_TRIM_LEVEL_FILTERING: 3,
    TOGGLE_3D_MODEL_SECTION: 4,
    SET_3D_MODEL_NAME_SEARCH: 5,
    SET_3D_MODEL_YEAR_SEARCH: 6,
    SET_VISUAL_MODEL: 7,
    SET_VISUAL_MODEL_OPTIONS: 8,
    SET_3D_MODEL_SECTION_LOADING: 9,
    TOGGLE_OEM_MODEL_SECTION: 10,
    SET_OEM_MODEL: 11,
    SET_OEM_MODEL_OPTIONS: 12,
    SET_OEM_MODEL_SECTION_LOADING: 13,
    TOGGLE_DISPLAY_TAGS_SECTION: 14,
    TOGGLE_DISPLAY_TAGS: 15,
    SET_INCOMPLETED_TAGGING_VEHICLES: 16,
    TOGGLE_IS_REVIEWED: 17,
    TOGGLE_IS_REVIEWED_SECTION: 18,
};

const bulkAssignVehicleReducer = (state, action) => {
    switch (action.type) {
        case BULK_ASSIGN_VEHICLE_ACTION_TYPE.RESET: {
            return BULK_ASSIGN_VEHICLE_INIT_STATE;
        }
        case BULK_ASSIGN_VEHICLE_ACTION_TYPE.TOGGLE_TRIM_LEVEL_FILTERING_SECTION: {
            return {
                ...state,
                trimLevelFiltering: {
                    ...state.trimLevelFiltering,
                    sectionDisabled: !state.trimLevelFiltering.sectionDisabled,
                },
            };
        }
        case BULK_ASSIGN_VEHICLE_ACTION_TYPE.TOGGLE_TRIM_LEVEL_FILTERING: {
            return {
                ...state,
                trimLevelFiltering: {
                    ...state.trimLevelFiltering,
                    isChecked: !state.trimLevelFiltering.isChecked,
                },
            };
        }
        case BULK_ASSIGN_VEHICLE_ACTION_TYPE.TOGGLE_3D_MODEL_SECTION: {
            return {
                ...state,
                threeDModel: {
                    ...state.threeDModel,
                    sectionDisabled: !state.threeDModel.sectionDisabled,
                },
            };
        }
        case BULK_ASSIGN_VEHICLE_ACTION_TYPE.SET_3D_MODEL_NAME_SEARCH: {
            return {
                ...state,
                threeDModel: {
                    ...state.threeDModel,
                    modelNameSearchValue: action.payload,
                },
            };
        }
        case BULK_ASSIGN_VEHICLE_ACTION_TYPE.SET_3D_MODEL_YEAR_SEARCH: {
            return {
                ...state,
                threeDModel: {
                    ...state.threeDModel,
                    modelYearSearchValue: action.payload,
                },
            };
        }
        case BULK_ASSIGN_VEHICLE_ACTION_TYPE.SET_VISUAL_MODEL: {
            return {
                ...state,
                threeDModel: {
                    ...state.threeDModel,
                    visualModelValue: action.payload,
                },
            };
        }
        case BULK_ASSIGN_VEHICLE_ACTION_TYPE.SET_VISUAL_MODEL_OPTIONS: {
            return {
                ...state,
                threeDModel: {
                    ...state.threeDModel,
                    visualModelValue: null,
                    visualModelOptions: action.payload,
                    loading: false,
                },
            };
        }
        case BULK_ASSIGN_VEHICLE_ACTION_TYPE.SET_3D_MODEL_SECTION_LOADING: {
            return {
                ...state,
                threeDModel: {
                    ...state.threeDModel,
                    loading: action.payload,
                },
            };
        }
        case BULK_ASSIGN_VEHICLE_ACTION_TYPE.TOGGLE_OEM_MODEL_SECTION: {
            return {
                ...state,
                oemModels: {
                    ...state.oemModels,
                    sectionDisabled: !state.oemModels.sectionDisabled,
                },
            };
        }
        case BULK_ASSIGN_VEHICLE_ACTION_TYPE.SET_OEM_MODELS: {
            return {
                ...state,
                oemModels: {
                    ...state.oemModels,
                    oemModelValue: action.payload,
                },
            };
        }
        case BULK_ASSIGN_VEHICLE_ACTION_TYPE.SET_OEM_MODEL_OPTIONS: {
            return {
                ...state,
                oemModels: {
                    ...state.oemModels,
                    oemModelOptions: action.payload,
                    oemModelValue: null,
                    loading: false,
                },
            };
        }
        case BULK_ASSIGN_VEHICLE_ACTION_TYPE.SET_OEM_MODEL_SECTION_LOADING: {
            return {
                ...state,
                oemModels: {
                    ...state.oemModels,
                    loading: action.payload,
                },
            };
        }
        case BULK_ASSIGN_VEHICLE_ACTION_TYPE.TOGGLE_DISPLAY_TAGS_SECTION: {
            return {
                ...state,
                displayTags: {
                    ...state.displayTags,
                    sectionDisabled: !state.displayTags.sectionDisabled,
                },
            };
        }
        case BULK_ASSIGN_VEHICLE_ACTION_TYPE.TOGGLE_DISPLAY_TAGS: {
            return {
                ...state,
                displayTags: {
                    ...state.displayTags,
                    isChecked: !state.displayTags.isChecked,
                },
            };
        }
        case BULK_ASSIGN_VEHICLE_ACTION_TYPE.SET_INCOMPLETED_TAGGING_VEHICLES: {
            return {
                ...state,
                displayTags: {
                    ...state.displayTags,
                    incompletedTaggingVehicles: action.payload,
                },
            };
        }
        case BULK_ASSIGN_VEHICLE_ACTION_TYPE.TOGGLE_IS_REVIEWED: {
            return {
                ...state,
                isReviewed: {
                    ...state.isReviewed,
                    isChecked: !state.isReviewed.isChecked,
                },
            };
        }
        case BULK_ASSIGN_VEHICLE_ACTION_TYPE.TOGGLE_IS_REVIEWED_SECTION: {
            return {
                ...state,
                isReviewed: {
                    ...state.isReviewed,
                    sectionDisabled: !state.isReviewed.sectionDisabled,
                },
            };
        }
    }
};

const BulkAssignVehicleModal = ({ oemId, isOpen, onClose, onSave, selectedVehicleIds, vehicles }) => {
    const { showToast } = useContext(ToastContext);

    const [state, dispatch] = useReducer(bulkAssignVehicleReducer, BULK_ASSIGN_VEHICLE_INIT_STATE);

    useEffect(() => {
        let isUnmounted = false;
        (async () => {
            if (isOpen) {
                try {
                    dispatch({ type: BULK_ASSIGN_VEHICLE_ACTION_TYPE.SET_OEM_MODEL_SECTION_LOADING, payload: true });
                    const oemlModelSearchResults = await requestFindVehicleOemModel(oemId);

                    const options = oemlModelSearchResults.map(vm => ({
                        label: `${vm.oemModelName}`,
                        value: vm.oemModelId,
                    }));
                    if (!isUnmounted) {
                        dispatch({ type: BULK_ASSIGN_VEHICLE_ACTION_TYPE.SET_OEM_MODEL_OPTIONS, payload: options });
                    }
                } catch (error) {
                    showToast(error);
                } finally {
                    if (!isUnmounted) {
                        dispatch({
                            type: BULK_ASSIGN_VEHICLE_ACTION_TYPE.SET_OEM_MODEL_SECTION_LOADING,
                            payload: false,
                        });
                    }
                }
            }
        })();

        return () => (isUnmounted = true);
    }, [isOpen, oemId, dispatch, showToast]);

    const selectedVehicles = vehicles.filter(v => selectedVehicleIds.includes(v.vehicleId));

    const handleCancelButtonClick = useCallback(() => {
        dispatch({ type: BULK_ASSIGN_VEHICLE_ACTION_TYPE.RESET });
        onClose();
    }, [onClose]);

    const handleApplyButtonClick = async () => {
        const payload = {};
        if (!state.trimLevelFiltering.sectionDisabled) {
            payload.trimLevelFiltering = state.trimLevelFiltering.isChecked;
        }
        if (!state.threeDModel.sectionDisabled) {
            payload.visualModelId = state.threeDModel.visualModelValue?.value ?? null;
        }
        if (!state.oemModels.sectionDisabled) {
            payload.oemModelIds = state.oemModels.oemModelValue?.map(x => x.value) ?? null;
        }
        if (!state.displayTags.sectionDisabled) {
            payload.displayTags = state.displayTags.isChecked;
        }
        if (!state.isReviewed.sectionDisabled) {
            payload.isReviewed = state.isReviewed.isChecked;
        }
        dispatch({ type: BULK_ASSIGN_VEHICLE_ACTION_TYPE.RESET });
        await onSave(payload);
    };

    const isApplyButtonDisabled =
        (state.trimLevelFiltering.sectionDisabled &&
            state.threeDModel.sectionDisabled &&
            state.oemModels.sectionDisabled &&
            state.displayTags.sectionDisabled &&
            state.isReviewed.sectionDisabled) ||
        (!state.displayTags.sectionDisabled && !state.displayTags.isChecked);

    return (
        <ReactModal
            id="bulk-assign-vehicle-modal"
            isOpen={isOpen}
            headerComponent={<BulkAssignVehicleModalHeader />}
            bodyComponent={
                <BulkAssignVehicleModalBody
                    isReviewedSectionDisabled={state.isReviewed.sectionDisabled}
                    isReviewedOn={state.isReviewed.isChecked}
                    trimLevelFilteringSectionDisabled={state.trimLevelFiltering.sectionDisabled}
                    isTrimLevelFilteringOn={state.trimLevelFiltering.isChecked}
                    threeDModelSectionDisabled={state.threeDModel.sectionDisabled}
                    threeDModelSectionLoading={state.threeDModel.loading}
                    threeDModelNameSearchValue={state.threeDModel.modelNameSearchValue}
                    threeDModelYearSearchValue={state.threeDModel.modelYearSearchValue}
                    visualModelOptions={state.threeDModel.visualModelOptions}
                    visualModelValue={state.threeDModel.visualModelValue}
                    oemModelSectionDisabled={state.oemModels.sectionDisabled}
                    oemModelOptions={state.oemModels.oemModelOptions}
                    oemModelValue={state.oemModels.oemModelValue}
                    oemModelSectionLoading={state.oemModels.loading}
                    displayTagsSectionDisabled={state.displayTags.sectionDisabled}
                    isDisplayingTagsOn={state.displayTags.isChecked}
                    selectedVehicles={selectedVehicles}
                    dispatch={dispatch}
                />
            }
            footerComponent={
                <BulkAssignVehicleModalFooter
                    applyButtonDisabled={isApplyButtonDisabled}
                    onCloseButtonClick={handleCancelButtonClick}
                    onApplyButtonClick={handleApplyButtonClick}
                />
            }
        />
    );
};

export default BulkAssignVehicleModal;
